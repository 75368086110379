import Promise from '../promise';
import * as xhr from './xhr';
import * as config from './servicesConfig';
import * as util from '../services/util';

const defaultOptions = {
  page: 1,
  pagesize: 50,
  positions: true
};

const getConveyances = function(type: string): (options: Object) => Promise<any> {
  return function(options: Object): Promise<any> {
    const mergedOptions = util.assign({}, defaultOptions, options);
    const url = `${config.getConfig().REALTY_ADDRESS}/${type}/conveyances/?${util.encodeUriQuery(mergedOptions)}`;

    return xhr.getJSON(url);
  };
};

export const getResource = function(path: string): (key: string, options?: Object) => Promise<any> {
  return function(key: string, options?: Object): Promise<any> {
    const mergedOptions = util.assign({}, defaultOptions, options);
    const url = `${config.getConfig().REALTY_ADDRESS}/${path}/${key}?${util.encodeUriQuery(mergedOptions)}`;

    return xhr.getJSON(url);
  };
};

export const getResourceByLink = function(url: string, options?: any): Promise<any> {
  url = util.addUrlParams(url, options);

  return xhr.getJSON(url);
};

export const getLandRegistry = function(cadastreKey: string, part: string, state: string): Promise<any> {
  const url = `${
    config.getConfig().REALTY_ADDRESS
  }/cadastres/${cadastreKey}/landregistry/?parts=${part}&state=${state}`;

  return xhr.getJSON(url);
};

export const getCadastreRight = function(rightKey: string): Promise<any> {
  const url = `${config.getConfig().REALTY_ADDRESS}/creationofrights/${rightKey}?includeFormatted=true`;

  return xhr.getJSON(url);
};

export const getCadastreOwnershipOwningThisCadastre = function(cadastreKey: string, options?: any): Promise<any> {
  const url = `${config.getConfig().REALTY_ADDRESS}/cadastres/${cadastreKey}/ownershipsowningthiscadastre/`;

  return getResourceByLink(url, options);
};

export const getCadastreBuildings = function(cadastreKey: string, options?: any): Promise<any> {
  const url = `${config.getConfig().REALTY_ADDRESS}/cadastres/${cadastreKey}/buildings/`;

  return getResourceByLink(url, options);
};

export const validateCadastre = function(cadastreKey: string): Promise<any> {
  const url = `${config.getConfig().REALTY_ADDRESS}/cadastres/${cadastreKey}/validation/`;

  return xhr.getJSON(url);
};

export const documentCopyExistenceCadastre = function(cadastreDocumentKey: string): Promise<any> {
  const url = `${config.getConfig().REALTY_ADDRESS}/cadastre/documentcopyexistence/${cadastreDocumentKey}`;

  return xhr.getJSON(url);
};

export const documentCopyExistenceShare = function(shareDocumentKey: string): Promise<any> {
  const url = `${config.getConfig().REALTY_ADDRESS}/share/documentcopyexistence/${shareDocumentKey}`;

  return xhr.getJSON(url);
};

export const getCreationOfRightsAsHtml = function(rightKey: string): Promise<any> {
  const url = `${
    config.getConfig().REALTY_ADDRESS
  }/creationofrights/${rightKey}?includeFormatted=false&contentType=html.body`;

  return xhr.getText(url);
};

export const queryResource = function(type: string): (options: Object) => Promise<any> {
  return function(options: Object): Promise<any> {
    const mergedOptions = util.assign({}, defaultOptions, options);
    const url = `${config.getConfig().REALTY_ADDRESS}/search/${type}/?${util.encodeUriQuery(mergedOptions)}`;

    return xhr.getJSON(url);
  };
};

export const getAddresses = getResource('addresses');
export const getCadastres = getResource('cadastres');
export const getCooperativeConveyances = getConveyances('cooperative');
export const getCooperativeInternals = getResource('coopinternals');
export const getCooperatives = getResource('housingcooperatives');
export const getPersons = getResource('persons');
export const getPlaces = getResource('places');
export const getPropertyConveyances = getConveyances('property');
export const getShares = getResource('shares');
export const getStreets = getResource('streets');

// region QueryResource functions are querying search rest APIs
export const queryAddresses = queryResource('addresses');
export const queryCadastres = queryResource('cadastres');
export const queryCooperatives = queryResource('housingcooperatives');
export const queryPersons = queryResource('persons');
export const queryPlaces = queryResource('places');
export const queryShares = queryResource('shares');
export const queryStreets = queryResource('streets');
// endregion
